@use "sass:math";

:root {
    --background-color: #222225;
    --font-color: #e8e9ed;
    --secondary-color: #a3abba;
    --global-space: 0.5em;
    --page-width: 75vw;
}

@import './mediaQueries.scss';
@import './about.scss';
@import './contact.scss';
@import './skills.scss';

body {
    .centered {
        display: flex;
        justify-content: center;
    }

    hr {
        margin-bottom: 0;
    }

    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 2em 0;

        border-bottom: 1px dashed var(--secondary-color);
    }

    .row-title {
        width: 100%;
        text-align: center;
    }
}

$img-width: 15em;
$min-img-width : 200px;

img {
    width: $img-width;
    min-width: $min-img-width;

    @include md {
        width: math.div($img-width, 1.5);
        min-width: math.div($min-img-width, 1.5);  
    }
}

.list-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .work-list {
        width: 90%;

        li {
            margin: 1em 0;
        }
    }
}

.jumbotron {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    .headshot-img {
        border-radius: $img-width * 0.5;
        border: 5px solid var(--primary-color);
    }

    * {
        margin: 0.5em 0;
    }
}