.contact-form {
    width: 70%;
    max-width: 500px;

    @include md {
        width: 100%;
    }
}

.submit-message {
    margin: auto;
    text-align: center;
}